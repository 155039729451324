import React, { useContext, useMemo, useState } from "react";
import Pagination from "../Pagination";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ApiDataContext } from "../../context/ApiDataContext";
import formatDate, { getDay, getMonth } from "../../utils/formatDate";

let PageSize = 5;

function EventList() {
    const [currentPage, setCurrentPage] = useState(1);
    const {data} = useContext(ApiDataContext);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, data]);

    return (
        <>
        <div className="events">
            {currentTableData.map(item => (
                <EventWrapper key={item.OccurrenceId}>
                    <DateBox>
                        <EventDay>{getDay(item.StartDateTime)}</EventDay>
                        <EventMonth>{getMonth(item.StartDateTime)}</EventMonth>
                    </DateBox>
                    <EventBody>
                        <LinkToEvent to={`/event/${item.OccurrenceId}`}><EventHeader>{item.Title}</EventHeader></LinkToEvent>
                        <EventDate>{formatDate(item.StartDateTime, item.EndDateTime)}</EventDate>
                        <EventLocation>{item.Location}</EventLocation>
                    </EventBody>
                </EventWrapper>
            ))}
        </div>
        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
        />
        </>
    )
}

const LinkToEvent = styled(Link)`
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const EventBody = styled.div``;

const EventLocation = styled.div``;

const DateBox = styled.div`
    text-align: center;
    border: 2px solid #292b2c;
    border-radius: 4px;
    padding: .5em 1em;
    margin-right: 1.25em;
`;

const EventDate = styled.div`
    
`;

const EventWrapper = styled.div`
    display: flex;
    margin-bottom: 2.5rem;
    align-items: flex-start;
    padding: 8px;
`;

const EventDay = styled.div`
    border: none;
    padding: 0;
    font-weight: 700;
    font-size: 150%;
    margin-top: -5px;
`;

const EventMonth = styled.div`
    font-size: 70%;
    font-weight: 700;
    line-height: .75em;
    text-transform: uppercase;
`;

const EventHeader = styled.div`
    font-size: 130%;
    color: rgb(181, 74, 16);
    margin-bottom: 0.25rem;
`;

export default EventList;